import React, {
  ForwardRefRenderFunction, PropsWithChildren, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState
} from 'react'
import { FileError, FileRejection, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import {
  Alert,
  Box, BoxProps, Button, CircularProgress, Fade, IconButton, Snackbar, Typography
} from '@mui/material'
import CopyrightIcon from '@mui/icons-material/Copyright'
import { FileCopyright } from '@typings/graphql'
// import DeleteIcon from '@mui/icons-material/Delete'

import { FilePreview } from './FilePreview'
import { ContentFile } from './content-type-editors/mutation-helper/file'
import { CopyrightEditDialog } from './dialogs/CopyrightEditDialog'

export type FileFormats = {
  'image/*'?: string[],
  'video/*'?: string[],
  'audio/*'?: string[],
  'text/html'?: string[],
  'text/plain'?: string[],
  'text/csv'?: string[],
  'text/vtt'?: string[],
  'application/json'?: string[],
  'application/zip'?: string[]
  'application/object'?: string[],
  'application/*'?: string[],
}

export type FileDragDropProps = PropsWithChildren & BoxProps & {
  preview?: boolean
  accept: FileFormats
  copyright?: boolean
  onFileChanged: (file: FileWithCopyright | null) => void
  dragPlaceholder?: string
  dropPlaceholder?: string
  initialFile?: Partial<ContentFile> | null
  loading?: boolean
  maxFileSize?: number
};

export type FileDragDropHandler = {
  open: () => void,
  reset: () => void
}

type FileDragDropRenderFn = ForwardRefRenderFunction<FileDragDropHandler, FileDragDropProps>

type FileWithCopyright = File & {
  copyright?: FileCopyright | null
}

const FileDragDrop: FileDragDropRenderFn = (props, ref) => {
  const {
    onFileChanged,
    accept,
    copyright,
    dragPlaceholder,
    dropPlaceholder,
    loading,
    initialFile,
    preview,
    maxFileSize = 20000000,
    children,
    ...boxProps
  } = props
  const { t } = useTranslation()

  const initialRender = useRef(true)

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)
  const [errorMessages, setErrorMessages] = useState<FileError[]>()
  const [showCopyrightDialog, setShowCopyrightDialog] = useState(false)
  const [file, setFile] = useState<FileWithCopyright | undefined>(initialFile as any)

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles?: FileRejection[]) => {
    if (rejectedFiles) {
      rejectedFiles.forEach(({ errors }) => {
        console.log(errors)
        setErrorMessages(errors)
        setSnackbarOpen(true)
      })
    }

    setFile(acceptedFiles[0])
  }, [])

  const openCopyrightDialog = (e: React.MouseEvent) => {
    e.stopPropagation()
    setShowCopyrightDialog(true)
  }

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false

      return
    }

    onFileChanged(file ?? null)
  }, [file])

  const updateCopyright = (newCopyright: FileCopyright | null) => {
    setFile((prev) => {
      if (!prev) {
        return prev
      }

      if (newCopyright) {
        delete newCopyright.__typename
      }

      return {
        ...prev,
        copyright: newCopyright
      }
    })

    setShowCopyrightDialog(false)
  }

  const previewFile = useMemo(() => {
    if (!preview || (!file && !initialFile)) {
      return null
    }

    if (file instanceof File) {
      return {
        url: URL.createObjectURL(file),
        fileName: file.name,
        mimeType: file.type,
        fileSize: file.size
      }
    }

    if (!initialFile) {
      return null
    }

    return {
      url: initialFile.url,
      fileName: initialFile.fileName,
      mimeType: initialFile.mimeType,
      fileSize: initialFile.fileSize
    }
  }, [file, initialFile])

  const {
    getRootProps, getInputProps, isDragActive, open
  } = useDropzone({
    accept,
    maxSize: maxFileSize,
    multiple: false,
    onDrop
  })

  useImperativeHandle(ref, () => ({
    open,
    reset: () => onDrop([])
  }))

  return (
    <>
      <Box display="flex" justifyContent="center" {...getRootProps()} {...boxProps} position="relative" overflow="hidden">
        <Button
          variant="text"
          sx={{
            border: 'dashed 1px',
            borderColor: 'grey.500',
            height: 'auto',
            maxHeight: '60vh',
            width: '100%',
            '&:hover': {
              borderColor: 'grey.700'
            },
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center'
          }}
        >
          { previewFile
            ? <Box width="100%" height="100%" p={1}><FilePreview preview={previewFile} /></Box>
            : children }
        </Button>

        <Box position="relative">
          <input {...getInputProps()} />
          {
        isDragActive
          ? dropPlaceholder && <Typography>{dropPlaceholder}</Typography>
          : dragPlaceholder && <Typography>{dragPlaceholder}</Typography>
      }
        </Box>

        {copyright && <Box position="absolute" top={4} right={4}>
          <IconButton onClick={openCopyrightDialog}
            size='small'
          >
            <CopyrightIcon />
          </IconButton>
        </Box>}

        {/* {previewFile && <Box position="absolute" bottom={4} left={4}>
          <IconButton
            size='small'
            onClick={(e) => {
              setFile(undefined)
              e.stopPropagation()
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>} */}

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={() => {
            setSnackbarOpen(false)
          }}
        >
          <Alert severity="error">
            {errorMessages && <Typography>{t('errors.invalidFileType')}</Typography>}
          </Alert>
        </Snackbar>

        <Fade in={loading}>
          <Box
            position="absolute"
            left={-10}
            top={-10}
            right={-10}
            bottom={-10}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ backdropFilter: 'blur(5px)' }}
          >
            <CircularProgress />
          </Box>
        </Fade>
      </Box>

      {copyright && <CopyrightEditDialog
        copyright={file?.copyright}
        open={showCopyrightDialog}
        onClose={() => setShowCopyrightDialog(false)}
        onSubmit={updateCopyright}
      />}
    </>
  )
}

export default React.forwardRef(FileDragDrop)
