import {
  useReorderContentNuggetsMutation,
  useTourPoiQuery
} from '@typings/graphql'
import React, { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Avatar, Box, Card, CardContent, Typography } from '@mui/material'
import NuggetCard from '@features/cms/components/ui/NuggetCard'
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from 'react-i18next'
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates
} from '@dnd-kit/sortable'
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'

const PoiNuggets: React.FC = () => {
  const { t } = useTranslation()
  const { id, modeId, poiId, sectionId } = useParams()
  const [reorderContentNuggets] = useReorderContentNuggetsMutation()
  const { data, refetch } = useTourPoiQuery({
    variables: { id: poiId as string }
  })
  const poi = data?.tourPoi

  const navigate = useNavigate()

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  useEffect(() => {
    refetch()
  }, [])

  const section = poi?.sections.find((s) => s.id === sectionId)
  const nuggets = section?.nuggets

  const sortedNuggets = useMemo(
    () =>
      [...(nuggets || [])].sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0)),
    [nuggets]
  )

  const nuggetCards = useMemo(
    () =>
      ((section && sortedNuggets) || []).map((nugget) => (
        <NuggetCard
          key={`nugget-${nugget.id}`}
          poiId={poi!.id}
          section={section!}
          nugget={nugget}
          onDuplicate={() => {
            refetch()
          }}
        />
      )),
    [sortedNuggets]
  )

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event

    if (!active || !over || !nuggets) {
      return
    }

    if (active.id !== over.id) {
      const ids = sortedNuggets.map((item) => item.id)
      const oldIndex = ids.indexOf(active.id as string)
      const newIndex = ids.indexOf(over.id as string)
      const newIds = arrayMove(ids, oldIndex, newIndex)

      await reorderContentNuggets({
        variables: {
          nuggetIds: newIds
        },
        optimisticResponse: {
          __typename: 'Mutation',
          reorderContentNuggets: newIds.map((nuggetId, index) => ({
            __typename: 'ContentNugget',
            id: nuggetId,
            order: index
          }))
        }
      })
    }
  }

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      width="100%"
      padding="32px"
      alignItems="flex-start"
      flex={1}
      sx={{
        overflowY: 'auto'
      }}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToFirstScrollableAncestor]}
      >
        <SortableContext
          items={sortedNuggets.map((nugget) => nugget.id)}
          strategy={rectSortingStrategy}
        >
          {nuggetCards}
        </SortableContext>
      </DndContext>
      <Card
        sx={{
          width: '250px',
          aspectRatio: '2/3',
          cursor: 'pointer',
          marginRight: '32px',
          marginBottom: '32px',
          borderRadius: '15px'
        }}
        onClick={() =>
          navigate(
                        `/tour/${id}/mode/${modeId}/poi/${poi!.id}/section/${section!.id}/nugget/create`
          )
                }
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Avatar
              sx={{
                width: 24,
                height: 24,
                backgroundColor: 'gray',
                color: '#fff',
                marginBottom: '8px',
                fontSize: '12px'
              }}
            >
              {nuggetCards.length + 1}
            </Avatar>
            <Typography
              textAlign="center"
              fontWeight="600"
              variant="h6"
              marginBottom="32px"
              sx={{ color: 'gray' }}
            >
              {t('edit.poi.newNugget')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '150px',
              height: '150px',
              borderRadius: '30px',
              border: '3px solid gray'
            }}
          >
            <AddIcon sx={{ fontSize: '80px', color: 'gray' }} />
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default PoiNuggets
