import { InMemoryCache } from '@apollo/client'

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        TourPoi: {
          keyArgs: ['id']
        },
        Content: {
          keyArgs: ['id']
        }
      }
    }
  }
})
